import React from "react";
// import ProductPage from "../ProductsPage/ProductPage.jsx";
import ProductPage from "../../components/Products/ProductPage";

import brands from "../../assets/img/products/ppe-safety/brands.png"

import p1 from "assets/img/products/ppe-safety/1.jpg";
import p2 from "assets/img/products/ppe-safety/2.jpg";
import p3 from "assets/img/products/ppe-safety/3.jpg";
import p4 from "assets/img/products/ppe-safety/4.jpg";
import p5 from "assets/img/products/ppe-safety/5.png";
import p6 from "assets/img/products/ppe-safety/6.jpg";
import p7 from "assets/img/products/ppe-safety/7.jpg";
// import p8 from "assets/img/products/ppe-safety/8.jpg";

import s1 from "assets/img/products/ppe-safety/slider/1.jpg";
import s2 from "assets/img/products/ppe-safety/slider/2.jpg";
import s3 from "assets/img/products/ppe-safety/slider/3.jpg";
import s4 from "assets/img/products/ppe-safety/slider/4.jpg";

export default () => (
  <ProductPage prod="PPE & Safety" url="ppe-safety" brandsImg={brands} products={products} sliders={sliders} />
);

const sliders = [
    { img: s1},
    { img: s2},
    { img: s3},
    { img: s4}
]

const products = [
    { name: 'Gloves',      img: p1 },
    { name: 'Helmet',          img: p2 },
    { name: 'Vest',            img: p3 },
    { name: 'Goggles',           img: p4 },
    { name: 'Shoes',           img: p5 },
    { name: 'Harness',             img: p6 },
    { name: 'Afrol',img: p7 }
]